import axios from '../../../axios';
import IOtpRequest from '../types/IOtpRequest';
import IUser from '../types/IUser';
import IValidateMFAParam from '../types/IValidateMFAParam';
import ResetPasswordRequest from '../types/ResetPasswordRequest';

export const loginUser = (user: IUser) => axios.post('/api/authenticate', user);
export const loginValidateMFA = (data: IValidateMFAParam) =>
  axios.post('/api/authenticate/mfa', data);
export const loginForgotPassword = (email: string) =>
  axios.post('/api/account/reset_password/init', email);
export const getWorkspaceUser = () => axios.get('api/account');
export const changePassword = (password: string) =>
  axios.post('api/account/change_password', password);
export const workSpaceMFALogin = (request: IOtpRequest) =>
  axios.post(
    `/api/workspace/submit-otp`,
    {},
    {
      params: request,
    }
  );
export const resetPassword = (request: ResetPasswordRequest) =>
  axios.post(`/api/account/reset_password/finish`, request);

export const activateAccount = (activationKey: string) =>
  axios.get(`/api/activate`, {
    params: {
      key: activationKey,
    },
  });
