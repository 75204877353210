import axios from 'axios';

import { IMultipleVendors } from '..';
import IMassMailingLogSearchFilterDTO from '../types/IMassMailingLogSearchFilterDTO';
import IVendorImportStats from '../types/IVendorImportStats';
import IVendorSearchFilterDTO from '../types/IVendorSearchFilterDTO';

const VENDOR_SEARCH_API_PREFIX = 'vendorsearch/api/';
const VENDOR_MAILING_CREDITS_API = `${VENDOR_SEARCH_API_PREFIX}mailingcredits`;

export const createMultipleVendors = (vendor: IMultipleVendors) =>
  axios.post('/vendorsearch/api/vendors/multiple', vendor);

export const uploadVendorFile = (vendorUploadRequest: IVendorImportStats) =>
  axios.post(`${VENDOR_SEARCH_API_PREFIX}vendors/bulk-upload`, vendorUploadRequest);

export const searchWithListAggregations = (vendorSearchFilterDTO: IVendorSearchFilterDTO) =>
  axios.post(`${VENDOR_SEARCH_API_PREFIX}_search/vendors/lists-aggregation`, vendorSearchFilterDTO);

export const vendorSearch = (vendorSearchFilterDTO: IVendorSearchFilterDTO) =>
  axios.post(`${VENDOR_SEARCH_API_PREFIX}_search/vendors`, vendorSearchFilterDTO);

export const massMailingLogSearch = (vendorSearchFilterDTO: IMassMailingLogSearchFilterDTO) =>
  axios.post(`${VENDOR_SEARCH_API_PREFIX}_search/vendors/mass-mailing-logs`, vendorSearchFilterDTO);

export const getVendorImportStats = () =>
  axios.get(`${VENDOR_SEARCH_API_PREFIX}vendors/import-stats`);

export const getTotalAllottedInWorkspace = () =>
  axios.get(`${VENDOR_MAILING_CREDITS_API}total-allotted`);

export const getUserCreditsDefinedByUserEmail = (userEmail: string) =>
  axios.get(`${VENDOR_MAILING_CREDITS_API}?userEmail=${userEmail}`);

export const getVendor = (id: string) => axios.get(`${VENDOR_SEARCH_API_PREFIX}vendors/${id}`);

export const getVendorList = (id: string) =>
  axios.get(`${VENDOR_SEARCH_API_PREFIX}vendorlists/${id}`);

export const downloadVendorList = (vendorSearchFilterDTO: IVendorSearchFilterDTO) =>
  axios.post(`${VENDOR_SEARCH_API_PREFIX}_search/vendors/downloadList.xls`, vendorSearchFilterDTO, {
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
    },
    responseType: 'arraybuffer',
  });
