export const checkForWorkspaceFeatures = async (
  workspaceFeatures: string[][] | undefined | null,
  feature: string
) => {
  const workspacePlan = workspaceFeatures;
  let featureList = [];
  let condition = 'AND';
  if (feature.indexOf('||') > -1) {
    featureList = feature.split('||');
    condition = 'OR';
  } else if (feature.indexOf('&&') > -1) {
    featureList = feature.split('&&');
  } else {
    featureList = [feature.trim()];
  }
  const firstPlan = workspacePlan && workspacePlan[0];
  const secondPlan = workspacePlan && workspacePlan[1];

  if (firstPlan && secondPlan) {
    for (let i = 0; i < featureList.length; i++) {
      const checkFeature = featureList[i].trim();
      const featureActive = firstPlan.filter(function (f) {
        return f && f.toLowerCase() == checkFeature.toLowerCase();
      })[0];
      const displayWhenInactive = secondPlan.filter(function (f) {
        return f && f.toLowerCase() == checkFeature.toLowerCase();
      })[0];
      const featureEnabled = featureActive || displayWhenInactive;
      if (featureEnabled && condition == 'OR') return true;
      else if (!featureEnabled && condition == 'AND') return false;
    }
    return true;
  }
  return false;
};

export const checkForActivation = (
  workspaceFeatures: string[][] | null | undefined,
  feature: string
) => {
  let featureList = [];
  let condition = 'AND';
  if (feature.indexOf('||') > -1) {
    featureList = feature.split('||');
    condition = 'OR';
  } else if (feature.indexOf('&&') > -1) {
    featureList = feature.split('&&');
  } else {
    featureList = [feature];
  }
  if (workspaceFeatures) {
    for (let i = 0; i < featureList.length; i++) {
      const checkFeature = featureList[i].trim();
      const featureActive = workspaceFeatures[0].filter(
        (f) => f && f.toLowerCase() == checkFeature.toLowerCase()
      )[0];
      if (featureActive && condition == 'OR') return true;
      else if (!featureActive && condition == 'AND') return false;
    }
    return true;
  }
};
