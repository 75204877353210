import axios from '../../../axios';
import { ITeam } from '../../TeamSearchService';
import { DataSharingSettingsPayload } from '../types/DataSharingSettingsDTO';
import IAddTeamMembersPayload from '../types/IAddTeamMembersPayload';
import ICreateTeam from '../types/ICreateTeam';

const teamsUrl = '/api/teams/';

const membersUrl = '/api/team-members/';

const wsreportingUrl = '/wsreporting/api/audittrial/byentity/';

export const createTeam = (data: ICreateTeam) => axios.post(`${teamsUrl}`, data);

export const editTeam = (data: ICreateTeam) => axios.put(`${teamsUrl}`, data);

export const addTeamMembers = (data: IAddTeamMembersPayload) =>
  axios.put(`${membersUrl}add-team-members`, data);

export const deleteTeamMember = (memberEmail: string, teamId: string) =>
  axios.delete(`${membersUrl + memberEmail}/${teamId}`);

export const getTeamHistory = (teamId: string) => axios.get(wsreportingUrl + teamId);
export const getDataSharingSettings = () => axios.get(`${teamsUrl}sharing`);
export const updateDataSharingSettings = (config: DataSharingSettingsPayload) =>
  axios.post(`${teamsUrl}sharing`, config);

export const getAllTeams = () => axios.get(`${teamsUrl}team`);
export const updateTeams = (config: ITeam) => axios.put(`${teamsUrl}`, config);
